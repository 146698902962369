<template>
  <div class="companies">
    <MobileTable
      v-model:selection="store.state.parking.selectedParkings"
      :label="$route.name"
      :columns="parking.columns"
      :data="store.state.parking.parkings"
    />
    <DesktopTable
      v-model:selection="store.state.parking.selectedParkings"
      :label="$route.name"
      :columns="parking.columns"
      :data="store.state.parking.parkings"
    />
    <Actions :items="parking.actions" />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/estacionamientos/nuevo");
    };
    const onModify = () => {
      if (store.state.parking.selectedParkings.length !== 1) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setOpenMode", openMode.MODIFY);
        router.push("/estacionamientos/modificar");
      }
    };
    const onRemove = async () => {
      if (store.state.parking.selectedParkings.length !== 1) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setLoading", true);
        const response = await store.dispatch(
          actions.parkingActions.delete,
          store.state.parking.selectedParkings[0].id
        );
        if (response.ok) {
          store.commit("setSelectedParkings", []);
          await store.dispatch(
            actions.parkingActions.findAll,
            store.state.general.selectedBuilding
          );
          store.commit("setLoading", false);
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
          store.commit("setLoading", false);
        }
      }
    };

    const parking = reactive({
      columns: [
        {
          field: "number",
          header: "Número",
          sort: true,
        },
        {
          field: "apportionment",
          header: "Prorrateo",
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/estacionamientos/nuevo"),
        },
        {
          name: "Modificar",
          action: onModify,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/estacionamientos/modificar"),
        },
        {
          name: "Eliminar",
          action: onRemove,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/estacionamientos/modificar"),
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedParkings", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(
        actions.parkingActions.findAll,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    return { store, parking };
  },
};
</script>

<style scoped></style>
